/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #17364f; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #57b0c4; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #17364f; /* The default color of the main navmenu links */
  --nav-hover-color: #2791b3; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #2791b3; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f9f9f9;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}
a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}
/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 20px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 84px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: #fff;
  background: #0c1d2e;
  font-size: 15px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 500;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: #57b0c4;
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 45px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Index Page Header
------------------------------*/
.index-page .header {
  --background-color: rgba(255, 255, 255, 0);
}

/* Index Page Header on Scroll
------------------------------*/
.index-page.scrolled .header {
  --background-color: #ffffff;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 12px;
    font-size: 15px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mbile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

/* Listing Dropdown - Desktop */
@media (min-width: 1200px) {
  .navmenu .listing-dropdown {
    position: static;
  }

  .navmenu .listing-dropdown ul {
    margin: 0;
    padding: 10px;
    background: var(--nav-dropdown-background-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .listing-dropdown ul li {
    flex: 1;
  }

  .navmenu .listing-dropdown ul li a,
  .navmenu .listing-dropdown ul li:hover > a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown ul li a:hover,
  .navmenu .listing-dropdown ul li .active,
  .navmenu .listing-dropdown ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
}

/* Listing Dropdown - Mobile */
@media (max-width: 1199px) {
  .navmenu .listing-dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .listing-dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .listing-dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }
}
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 80px 0 60px 0;
  display: flex;
  align-items: center;
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin: 5px 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px;
  border-radius: 4px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}
.hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
/* Fonts */
:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Nunito", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}
.section-title.no-pb {
  padding-bottom: 20px;
}
.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  color: var(--accent-color);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: var(--default-font);
}

.section-title p {
  color: var(--heading-color);
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  font-family: var(--heading-font);
}

.section-title p .description-title {
  color: var(--accent-color);
}
.m-w {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 98px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 56px;
  }
}
.alt-features .icon-box p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  color: var(--accent-color);
  line-height: 0;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}
footer.footer-section {
  background-color: #173750;
  color: #fff;
  padding: 20px 0px;
}
#loom-companion-mv3 {
  display: none;
}
/*--------------------------------------------------------------
# Values Section
--------------------------------------------------------------*/
.values .card {
  background-color: var(--surface-color);
  color: var(--default-color);
  padding: 30px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s;
  height: 100%;
  border: 0;
}

.values .card img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .card h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .card:hover {
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
}

.values .card:hover img {
  transform: scale(1);
}
button.mobile-nav-toggle {
  border: none;
  background: no-repeat;
  display: none;
  position: absolute;
  right: 0;
}

@media (max-width: 640px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .Consent .form-group {
    display: flex;
    flex-wrap: wrap;
  }
}
.hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}
.mobile-nav-toggle-remove {
  display: none;
  background: none;
  border: none;
}
@media (max-width: 1199px) {
  section#hero {
    text-align: center;
    margin-top: 50px;
  }
  button.mobile-nav-toggle {
    display: block;
  }
  .header .logo img {
    max-height: 54px;
    margin-right: 8px;
  }

  .mobile-nav-toggle-remove {
    display: block;
    float: right;
    margin-top: 10px;
    margin-right: 11px;
  }
  .navmenu ul {
    display: block;
    position: relative;
    box-shadow: none;
  }
  .header .navmenu {
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    right: -100%;
    height: 100%;
    width: calc(100% - 80px);
    box-shadow: none;
    transition: all 0.5s ease;
  }
  .navmenu.menu-active {
    right: 0;
  }
}
.Consent form {
  max-width: 560px;
  margin: 0 auto;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 8px;
}
.Consent .form-group {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.Consent label {
  width: 200px;
}
button.btn.btn-primary {
  background-color: #57b0c4;
  border-color: #57b0c4;
}
